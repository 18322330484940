var PageState = require('../../js/state')
    .PageState;
var formBind = require('../../js/form-bind');
var signupForm = require('./signup-form');

var SignupPage = PageState.generate(
    function SignupPage() {
        var _ = this;

        PageState.call(_);

        _.signupForm = signupForm;
    }
);

SignupPage.definePrototype({
    bind: function bind(app) {
        var _ = this;

        signupForm.app = app;

        formBind(app, _.signupForm);
    }
});

module.exports = SignupPage;
