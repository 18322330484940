var $ = require('jquery');

var XRegExp = require('xregexp');

function InputBlocker(app) {
    window.REG_EXPS = {};
    app.view.on('input', 'input[data-blocker]', function (evt, target) {
        var blocker = $(target)
            .data('blocker');

        var regExp = REG_EXPS[blocker];

        if (!regExp) {
            regExp = new XRegExp(blocker, 'g');
            REG_EXPS[blocker] = regExp;
        }

        target.value = target.value.replace(regExp, '');
    });
}

module.exports = InputBlocker;
