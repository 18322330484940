var request = require('./request');
var getSecureStorage = require('./secure-storage');
var _upload_ = require('./api-lib/upload');

var uuid = require('uuid/v4');

var api = {};

api.init = function (env) {
    var _ = this;
    _.storage = getSecureStorage(localStorage, env.env);

    _.account = _.storage.getItem('account');
    _.url = env.api_url + '/' + env.api + '/';
};

function uploadFile(url, fileOrBlob, contentType, cb, progress) {
    var xhr = new XMLHttpRequest();
    xhr.onerror = function (e) {
        // handle failture
        cb(e, null);
    };

    xhr.upload.addEventListener('progress', function (e) {
        // handle notifications about upload progress: e.loaded / e.total
        if (progress) {
            progress(e, Math.floor(e.loaded / e.total) * 100);
        }
    }, false);

    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status >= 200 && xhr.status <= 299) {
                // upload completed
                cb(null, xhr.responseText);
            } else {
                // failed with error messge from server
                cb(xhr.responseText, null);
            }
        }
    };

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', contentType);
    xhr.send(fileOrBlob);
}

function api_call(route, method, payload, cb) {
    logger.log(route, method, payload);
    request(
        api.url + route, {
            method: method,
            body: JSON.stringify(payload)
        },
        function (status, res) {
            try {
                res = JSON.parse(res);
                if (status !== 200 || res.errorMessage) {
                    cb(res, null);
                } else {
                    cb(null, res);
                }
            } catch (e) {

                logger.error(e, res, status);
                cb(e, null);
            }
        }
    );
}

api.otpPhone = function otpPhone(payload, cb) {
    api_call('otpPhone', 'POST', payload, cb);
};

api._public_login = function _public_login(payload, cb) {
    api_call('_public_login', 'POST', payload, function (err, res) {
        api.account = res;
        if (api.storage) {
            api.storage.setItem('account', api.account);
        }
        cb(err, res);
    });
};

api.otpSignup = function otpSignup(payload, cb) {
    payload.token = api.account ? api.account.token : '';
    payload.picture = payload.picture ||
        'https://d33ebmjpjljz1r.cloudfront.net/default_profile_pic.png';
    api_call('otpSignup', 'POST', payload, cb);
    if (api.account.user) {
        delete payload.token;
        for (var key in payload) {
            if (payload.hasOwnProperty(key)) {
                api.account.user[key] = payload[key];
            }
        }
        if (api.storage) {
            api.storage.setItem('account', api.account);
        }
    }
};

api.logout = function logout(cb) {
    // api_call('logout', 'POST', payload, cb);
    if (api.storage) {
        api.storage.removeItem('account');
    }
    api.account = null;

    cb(null, {
        message: 'User signed out.'
    });
};

api.saveMessage = function saveMessage(payload, cb) {
    payload.token = api.account ? api.account.token : '';
    api_call('saveMessage', 'POST', payload, cb);
};

api.sendMessage = function sendMessage(payload, cb) {
    payload.token = api.account ? api.account.token : '';
    api_call('sendMessage', 'POST', payload, cb);
};

api.getProfile = function getProfile(payload, cb) {
    payload.token = api.account ? api.account.token : '';
    api_call('publicProfile', 'POST', payload, cb);
};

api.getStory = function getStory(payload, cb) {
    api_call('publicStory', 'POST', payload, cb);
};

api.checkAvailableUsername = function checkAvailableUsername(payload, cb) {
    payload.token = api.account ? api.account.token : '';
    if (
        api.account &&
        api.account.user &&
        api.account.user.username === payload.username
    ) {
        cb(null, {
            available: true
        });
    } else {
        api_call('availableUsername', 'POST', payload, cb);
    }
};

api.s3creds = function s3creds(payload, cb) {
    payload.token = api.account ? api.account.token : '';
    api_call('s3creds', 'POST', payload, cb);
};

api.updateProfilePicture = function updateProfilePicture(file, cb, progress) {
    api.upload([file], function (err, res) {
        if (err) {
            return cb(err, null);
        }

        var payload = {
            token: api.account ? api.account.token : '',
            picture: res.url
        };

        api_call('changeProfilePicture', 'POST', payload, function (err, res2) {
            if (!err) {
                api.account.user.picture = res.url;
                if (api.storage) {
                    api.storage.setItem('account', api.account);
                }
            }
            cb(err, err ? null : res2);
        });
    }, progress);
};

api.upload = function upload(file, cb, progress) {
    var files = file instanceof Array ? file : [file];

    files.forEach(function (file) {
        var key = uuid() + '.' +
            file.name.slice(
                (Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1
            )
            .toLowerCase();

        api.s3creds({
            contentType: file.type,
            key: key
        }, function (err, res1) {

            if (err) {
                logger.log('Error s3creds: ', err);
                return cb(err, null);
            } else {
                //fix broken urls
                res1.url = res1.url.replace(
                    'sndmsg-messageimages.s3-accelerate.amazonaws.com',
                    'd33ebmjpjljz1r.cloudfront.net'
                );

                _upload_(
                    res1.signedUrl,
                    file.file,
                    file.type,
                    function (err,
                        res) {
                        if (err) {
                            logger.log('Error uploadFile: ', err);
                        }

                        cb(err, err ? null : res1, file);
                    },
                    progress
                );
            }
        });
    });
};

module.exports = api;
