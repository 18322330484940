module.exports = {
    'nav': require('./templates/nav.bars'),
    'unsupported': require('./unsupported.bars'),
    'side-nav': require('./templates/side-nav.bars'),
    'footer': require('./templates/footer.bars'),
    'video-player': require('./templates/video-player.bars'),
    'form-validation': require('./templates/form-validation.bars'),
    'modal': require('./templates/modal.bars'),

    //modals
    'join-with-phone': require('./templates/modals/join-with-phone.bars'),
    'modal-share-story': require('./templates/modals/share-story.bars'),
    'modal-upload-story': require('./templates/modals/upload-story.bars'),

    //pages
    '404': require('./pages/404/index.bars'),
    'account': require('./pages/account/index.bars'),
    'compose': require('./pages/compose/index.bars'),
    'compose-item': require('./pages/compose/compose-item.bars'),
    'login': require('./pages/login/index.bars'),
    'profile': require('./pages/profile/index.bars'),
    'signup': require('./pages/signup/index.bars'),
    'story': require('./pages/story/index.bars'),
    'story-item': require('./pages/story/story-item.bars'),

    // components
    'smart-formatter': require('./components/smart-formatter/index.bars'),
};
