var PageState = require('../../js/state')
    .PageState;

var Sender = require('../../js/Sender');
var api = require('../../js/api');

var ProfilePage = PageState.generate(
    function ProfilePage() {
        var _ = this;

        PageState.call(_);
    }
);

ProfilePage.definePrototype({
    loadData: function loadData(sender, stories) {
        var _ = this;

        _.sender = new Sender(sender);
        _.stories = stories;
    },
    bind: function bind(app) {
        var _ = this;
        _.app = app;
    },
    handleScrollBottom: function handleScrollBottom() {
        var _ = this;

        if (!_._noNewContent) {
            _.pageLoading = true;
            _.app.render();

            var olderThan;

            if (_.stories && _.stories.length) {
                olderThan = _.stories[_.stories.length - 1].time_updated;
            }
            api.getProfile({
                username: _.sender.username,
                olderThan: olderThan
            }, function (err, res) {
                _.pageLoading = false;
                _.app.render();
                logger.log('load more stories', err, res);
                if (err || !res || !res.stories) {
                    _._noNewContent = true;
                    return logger.error('failed to load more stories', err, res);
                }
                if (!res.stories.length) {
                    _._noNewContent = true;
                } else {
                    _.stories = (_.stories || [])
                        .concat(res.stories);
                    _.app.render();
                }
            });
        } else {
            clearTimeout(_._noNewContentTimeout);
            _._noNewContentTimeout = setTimeout(
                function () {
                    _._noNewContent = false;
                },
                2000
            );
        }
    }
});

module.exports = ProfilePage;
