var Generator = require('generate-js');
var assert = require('./assert');
var request = require('./request');
var async = require('async');
var api = require('./api');
var ImagePlayer = require('./image-player');
var VideoPlayer = require('./video-player');
// var AudioPlayer = require('./audio-player');

function isMedia(type) {
    return type === 'photo' || type === 'video'; // || type === 'audio';
}

var StoryItem = Generator.generate(function StoryItem(item, story) {
    var _ = this;

    assert(Story.isCreation(story), 'story must be of type Story');

    _.story = story;

    _.type = item.type;
    _.isMedia = isMedia(item.type);
    _.value = item.value;
    _.style = item.style;
    _.preview = item.preview;
    _.previewFile = item.previewFile;
    _.file = item.file;
    _.name = item.file && item.file.name;
    _.height = item.height;
    _.width = item.width;

    if (_.type === 'photo') {
        _.player = new ImagePlayer();
    } else if (_.type === 'video') {
        _.player = new VideoPlayer();
    } else if (_.type === 'audio') {
        // _.player = new AudioPlayer();
    }
});

StoryItem.definePrototype({
    showTool: {
        get: function get() {
            var _ = this;

            return _.isMedia || _._show_tool;
        }
    },
    upload: function upload(cb, progress) {
        var _ = this;

        if (
            (
                _.file ||
                _.previewFile

            ) &&
            (
                _.type === 'photo' ||
                _.type === 'video' ||
                _.type === 'audio'
            )
        ) {
            // upload file then call cb
            var files = [];
            var errors = [];
            if (_.file) files.push(_.file);
            if (_.previewFile) files.push(_.previewFile);

            var calls = 0;
            api.upload(files, function _done(err, res, file) {
                if (res) {
                    if (file === _.file) {
                        _.value = res.url;
                        delete _.file;
                    } else if (file === _.previewFile) {
                        _.preview = res.url;
                        delete _.previewFile;
                    }
                }
                calls++;

                if (err) errors.push(err);

                if (calls === files.length) {
                    cb(errors.length ? errors : null);
                }

            }, function _progress(uploaded) {
                _.uploaded = uploaded;

                if (progress) {
                    progress(_);
                }
            });
        } else {
            cb(null);
        }
    },
    toJSON: function toJSON() {
        var _ = this;
        var obj = {
            type: _.type,
            value: _.value,
        };

        if (_.type === 'text') {
            obj.style = _.style;
        } else if (
            _.type === 'photo' ||
            _.type === 'video'
        ) {
            if (_.type === 'video') {
                obj.preview = _.preview;
            }
            obj.height = _.height;
            obj.width = _.width;
        } else {
            return null;
        }

        return obj;
    },
    destroy: function destroy() {
        var _ = this;

        if (_.player) {
            _.player.destroy();
        }
    },
    remove: function remove() {
        var _ = this;

        _.destroy();

        if (Story.isCreation(_.story)) {
            var index = _.story.content.indexOf(_);
            if (index !== -1) {
                _.story.content.splice(index, 1);
                return true;
            }
        }

        return false;
    },
    insertBefore: function insertBefore(item) {
        var _ = this;

        if (Story.isCreation(_.story)) {
            var index = _.story.content.indexOf(_);
            if (index !== -1) {
                item = new StoryItem(item, _.story);
                logger.log('insertBefore', _.story, index, _, item);
                _.story.content.splice(index, 0, item);
                return item;
            }
        }

        return null;
    },
    insertAfter: function insertAfter(item) {
        var _ = this;

        if (Story.isCreation(_.story)) {
            var index = _.story.content.indexOf(_);
            if (index !== -1) {
                item = new StoryItem(item, _.story);
                logger.log('insertAfter', _.story, index, _, item);
                _.story.content.splice(index + 1, 0, item);
                return item;
            }
        }

        return null;
    },
    next: function next() {
        var _ = this;

        if (Story.isCreation(_.story)) {
            var story = _.story.root;
            var index = story.indexOf(_);
            if (index !== -1) {
                return story.getItem(index + 1);
            }
        }

        return null;
    },
    prev: function prev() {
        var _ = this;

        if (Story.isCreation(_.story)) {
            var story = _.story.root;
            var index = story.indexOf(_);
            if (index > 0) {
                return story.getItem(index - 1);
            }
        }

        return null;
    }
});

var Story = Generator.generate(function Story(story, parent) {
    var _ = this;
    _.__story__ = story;
    _.parent = parent || null;
    _.content = [];
    _.updates = [];

    if (story) {
        for (var key in story) {
            if (story.hasOwnProperty(key)) {
                _[key] = story[key];
            }
        }

        if (story.content) {
            _.content = [];
            for (var i = 0; i < story.content.length; i++) {
                _.content[i] = new StoryItem(story.content[i], _);
            }
        }

        if (story.updates) {
            _.updates = [];
            for (var i = 0; i < story.updates.length; i++) {
                _.updates[i] = new Story(story.updates[i], _);
            }
        }
    }

    if (!parent && (!story || story.draft === void(0))) {
        _.draft = true;
    }

    _.defineProperties({
        writable: true
    }, {
        _story: _._getState()
    });
});

Story.definePrototype({
    root: {
        get: function get() {
            var _ = this;

            var root = _;

            while (Story.isCreation(root.parent)) {
                root = root.parent;
            }

            return root;
        }
    },
    stories: {
        get: function get() {
            var _ = this;

            var stories = [_];

            for (var i = 0; i < _.updates.length; i++) {
                stories = stories.concat(_.updates[i].stories);
            }

            return stories;
        }
    },
    contents: {
        get: function get() {
            var _ = this;

            var items = [].concat(_.content);

            for (var i = 0; i < _.updates.length; i++) {
                items = items.concat(_.updates[i].contents);
            }

            return items;
        }
    },
    length: {
        get: function get() {
            var _ = this;

            var len = _.content.length;

            for (var i = 0; i < _.updates.length; i++) {
                len += _.updates[i].length;
            }

            return len;
        }
    },
    getItem: function getItem(index, reverseLookup) {
        var _ = this;

        if (reverseLookup !== false && index < 0) {
            index = _.length + index;
        }

        if (index < _.content.length) {
            return _.content[index];
        }

        index -= _.content.length;

        for (var i = 0; i < _.updates.length; i++) {
            var ref = _.updates[i].getItem(index, reverseLookup);
            if (ref) {
                return ref;
            } else {
                index -= _.updates[i].length;
            }
        }

        return null;
    },
    addItem: function addItem(item, index, reverseLookup) {
        var _ = this,
            stories = _.stories,
            story;

        item = new StoryItem(item, _);

        var ref = _.getItem(index, reverseLookup);

        if (ref) {
            ref.insertBefore(item);
            ref = null;
        } else {
            story = stories[stories.length - 1];

            if (story.messageId && !story.draft) {
                story = new Story({}, _);
                _.updates.push(story);
            }

            story.content.push(item);
        }

        return item;
    },
    removeItem: function removeItem(index, reverseLookup) {
        var _ = this;
        var ref = _.getItem(index, reverseLookup);

        if (ref) {
            ref.remove();
            ref = null;
            return true;
        }

        return false;
    },
    focus: function focus(index, reverseLookup) {
        var _ = this;

        var item = _.getItem(index, reverseLookup);

        for (var i = 0; i < _.contents.length; i++) {
            _.contents[i]._show_tool = false;
        }

        if (item) {
            item._show_tool = true;
        }
    },

    focusItem: function focusItem(item) {
        var _ = this;

        for (var i = 0; i < _.contents.length; i++) {
            _.contents[i]._show_tool = false;
        }

        if (item) {
            item._show_tool = true;
        }
    },
    indexOf: function indexOf(item) {
        var _ = this;

        return _.contents.indexOf(item);
    },
    hasItem: function hasItem(item) {
        var _ = this;

        return _.contents.indexOf(item) !== -1;
    },

    hasUpdates: function hasUpdates(id) {
        var _ = this;

        for (var i = 0; i < _.updates.length; i++) {
            if (_.updates[i].messageId === id) {
                return true;
            }
        }
        return false;
    },

    addUpdates: function addUpdates(updates) {
        var _ = this;

        for (var i = 0; i < updates.length; i++) {
            if (!_.hasUpdates(updates[i].messageId)) {
                _.updates.push(new Story(updates[i], _));
            }
        }
    },

    sendJSON: function sendJSON() {
        var _ = this;

        logger.log('sendJSON', _);

        return {
            messageId: _.messageId,
            content: _.content,
            replyTo: _.replyTo,
            updateTo: !_.replyTo && _.parent ? _.parent.messageId : void(0)
        };
    },

    _getState: function _getState() {
        var _ = this;

        return JSON.stringify(_.sendJSON());
    },

    updatesHaveChanges: function updatesHaveChanges() {
        var _ = this;

        for (var i = 0; i < _.updates.length; i++) {
            if (_.updates[i].hashChanges()) {
                return true;
            }
        }

        return false;
    },
    _hashChanges: function _hashChanges() {
        var _ = this;

        return _._getState() !== _._story;
    },
    hashChanges: function hashChanges() {
        var _ = this;

        return _._hashChanges() || _.updatesHaveChanges();
    },

    _save: function _save(cb, progress) {
        var _ = this;

        if (!_._hashChanges()) {
            return cb();
        }

        async.each(
            _.content,
            function (item, next) {
                // logger.log('uploading', item);
                item.upload(next, progress);
            },
            function (err) {
                // post story when done uploading media
                if (err) {
                    cb(err, null);
                } else {
                    logger.log('saveMessage', _);
                    api.saveMessage(_.sendJSON(), function (err, res) {
                        if (err) {
                            return cb(err, null);
                        }

                        _.messageId = res.messageId;
                        _.webCode = res.webCode;

                        _._story = _._getState();

                        cb(null, res);
                    });
                }
            }
        );
    },

    _fetchPreviewImage_: function _fetchPreviewImage_(cb) {
        var _ = this;

        function load() {
            request(
                'https://d33ebmjpjljz1r.cloudfront.net/' + _.webCode + '.jpg', {},
                function (status, res) {
                    if (status !== 200) {
                        setTimeout(load, 500);
                    } else {
                        cb();
                    }
                }
            );
        }

        load();
    },

    save: function save(cb, progress) {
        var _ = this;

        async.each(
            _.stories,
            function (story, next) {
                story._save(function (err, res) {
                    logger.log('what is happening:', err, res);
                    next(err, res);
                }, progress);
            },
            function (err, res) {
                if (err) {
                    return cb(err);
                }

                _._fetchPreviewImage_(function () {
                    cb(err, res);
                });
            }
        );
    }
});

module.exports = Story;
