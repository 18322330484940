var PageState = require('../../js/state')
    .PageState;

var Sender = require('../../js/Sender');
var Story = require('../../js/Story');
var api = require('../../js/api');

var StoryPage = PageState.generate(
    function StoryPage() {
        var _ = this;

        PageState.call(_);
    }
);

StoryPage.definePrototype({
    loadData: function loadData(sender, story) {
        var _ = this;

        _.sender = new Sender(sender);
        _.story = new Story(story);
        _.emit('data');
    },
    bind: function bind(app) {
        var _ = this;

        _.app = app;
    },
    handleScrollBottom: function handleScrollBottom() {
        var _ = this;

        if (_.app && _.story.webCode && !_._noNewContent) {
            _.pageLoading = true;
            _.app.render();

            var newerThan;
            if (_.story.updates.length) {
                newerThan = _.story.updates[_.story.updates.length - 1].time_created;
            }

            api.getStory({
                username: _.sender.username,
                webcode: _.story.webCode,
                newerThan: newerThan
            }, function (err, res) {
                _.pageLoading = false;
                _.app.render();
                logger.log('load more updates', err, res);
                if (err || !res || !res.updates) {
                    _._noNewContent = true;
                    return logger.error('failed to load more updates', err, res);
                }
                if (!res.updates.length) {
                    _._noNewContent = true;
                } else {
                    _.story.addUpdates(res.updates);
                    _.app.render();
                }
            });
        } else {
            clearTimeout(_._noNewContentTimeout);
            _._noNewContentTimeout = setTimeout(
                function () {
                    _._noNewContent = false;
                },
                2000
            );
        }
    }
});

module.exports = StoryPage;
