var $ = require('jquery');

function scrollPager(app) {
    logger.log('scroll', app);
    app.document.on('scroll', function (evt) {

        var height = $(document)
            .height() - screen.availHeight;

        var position = $(document)
            .scrollTop();

        if (position >= height - 100) {
            if (!scrollPager._handleScrollBottom &&
                app.state.pageState &&
                app.state.pageState.handleScrollBottom
            ) {
                app.state.pageState.handleScrollBottom();
            }
            scrollPager._handleScrollBottom = true;
        } else {
            scrollPager._handleScrollBottom = false;
        }
    });
}

module.exports = scrollPager;
