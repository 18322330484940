var Clipboard = require('clipboard');

function _clipboard(app) {
    var clipboard = null;

    function rebind() {
        if (clipboard) {
            clipboard.destroy();
        }

        clipboard = new Clipboard('[data-clipboard-target]');
    }

    app.on('render', rebind);
    app.on('append', rebind);
}

module.exports = _clipboard;
