var $ = require('jquery');

function nav(app) {
    var closingTimeout, openingTimeout;

    app.state.sideNavOpen = false;

    app.view.on('click', '.open-nav', function (evt, target) {
        evt.preventDefault();

        clearTimeout(openingTimeout);
        clearTimeout(closingTimeout);

        if (app.state.sideNavOpen) {
            app.state.sideNavOpen = null;

            closingTimeout = setTimeout(function () {
                app.state.sideNavOpen = false;
                app.render();
            }, 200);
        } else {
            app.state.sideNavOpen = null;

            openingTimeout = setTimeout(function () {
                app.state.sideNavOpen = true;
                app.render();
            }, 200);
        }

        app.render();
        return false;
    });

    app.document.on('click', function (evt, target) {
        clearTimeout(openingTimeout);
        clearTimeout(closingTimeout);

        if (app.state.sideNavOpen) {
            app.state.sideNavOpen = null;

            closingTimeout = setTimeout(function () {
                app.state.sideNavOpen = false;
                app.render();
            }, 200);
        }
    });

    function fixScrollNav() {

        var top = $(window)
            .scrollTop() ||
            $('body')
            .scrollTop();

        var scrollNav;

        if (top > 60) {
            scrollNav = true;
        } else {
            scrollNav = false;
        }

        if (app.state.scrollNav !== scrollNav) {
            app.state.scrollNav = scrollNav;
            app.render();
        }
    }

    app.document.on('scroll', fixScrollNav);

    app.on('render', fixScrollNav);
    app.on('append', fixScrollNav);

}

module.exports = nav;
