var Modal = require('./modal');

function modals(app) {
    var modal = new Modal(app);

    modal.create({
        name: 'join-with-phone',
        title: 'Join',
        body: 'join-with-phone'
    });

    modal.create({
        name: 'login',
        title: 'Login',
        body: 'modal-login'
    });

    modal.create({
        name: 'login-code',
        title: 'Login',
        body: 'modal-login-code'
    });

    modal.create({
        name: 'share-story',
        title: 'Share your story',
        body: 'modal-share-story'
    });

    modal.create({
        name: 'upload-story',
        title: 'Uploading your content',
        body: 'modal-upload-story'
    });

    app.modal = app.state.modal = modal;
}

module.exports = modals;
