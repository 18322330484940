var Form = require('check-form');
var api = require('../../js/api');
var Sender = require('../../js/Sender');
var validation = require('../../js/validation');

var loginData = null;

var loginForm = new Form({
    name: 'login',
    fields: {
        phone: validation.phone
    },
    action: function (data, callback) {
        api.otpPhone(data, function (err, res) {
            logger.log(err, res);
            if (err || !res.methodId) {
                callback(err || !res.methodId, null);
            } else {
                loginData = res; //do something with res
                app.loginPage.code = true;

                app.signupPage.signupForm.fill(data);
                callback(null, res);
            }
        });
    }
});

exports.loginForm = loginForm;

var loginCodeForm = new Form({
    name: 'login-code',
    fields: {
        code: validation.code
    },
    action: function (data, callback) {
        app.loginPage.code = false;
        if (loginData && loginData.methodId) {

            data.methodId = loginData.methodId;

            logger.log('login-code', data);

            api._public_login(data, function (err, res) {
                logger.log(err, res);
                if (err) {
                    callback(err, null);
                } else {
                    app.state.account = res;

                    if (res.loginState === 2) {
                        app.router.go(
                            '/signup?redirect=' +
                            encodeURIComponent(
                                app.router.req.search.redirect ||
                                '/compose'
                            )
                        );
                    } else if (res.loginState === 3) {
                        app.state.user = new Sender(res.user);

                        if (app.state.page === 'login') {
                            app.router.go(
                                app.router.req.search.redirect ||
                                '/' + app.state.user.username
                            );
                        } else {
                            app.router.reload();
                        }
                    }

                    callback(null, res);
                }
            });
        }
    }
});

exports.loginCodeForm = loginCodeForm;
