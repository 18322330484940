var Form = require('check-form');
var api = require('../../js/api');
var validation = require('../../js/validation');
var Sender = require('../../js/Sender');

var accountForm = new Form({
    name: 'account',
    fields: {
        phone: validation.phone,
        username: validation.username,
        fullName: validation.fullName,
        picture: validation.picture
    },
    action: function action(data, callback) {
        var _ = this;
        var app = _.app;

        //TODO: update account with api
        logger.log('update account', data);
        api.otpSignup(data, callback);
    },
});

module.exports = accountForm;
