function formBind(app, checkForm) {
    app.view.on('input keydown', 'form#' + checkForm.name, function (evt, form) {
        var data = {};
        data[evt.target.name] = evt.target.value;
        checkForm.fill(data);
    });

    app.view.on('submit', 'form#' + checkForm.name, function (evt, form) {
        evt.preventDefault();

        checkForm.submit(function (err, res) {
            if (!err) {
                form.reset();
            }
            app.render();
        });

        return false;
    });

    checkForm.on('validation', function () {
        app.render();
    });
}

module.exports = formBind;
