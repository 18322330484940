module.exports={
  "_args": [
    [
      {
        "raw": "bars@latest",
        "scope": null,
        "escapedName": "bars",
        "name": "bars",
        "rawSpec": "latest",
        "spec": "latest",
        "type": "tag"
      },
      "/Users/mjong/Sites/Sidestorey/sidestory.com"
    ]
  ],
  "_from": "bars@latest",
  "_id": "bars@1.9.5",
  "_inCache": true,
  "_location": "/bars",
  "_nodeVersion": "6.10.2",
  "_npmOperationalInternal": {
    "host": "packages-18-east.internal.npmjs.com",
    "tmp": "tmp/bars-1.9.5.tgz_1493051671100_0.39524977654218674"
  },
  "_npmUser": {
    "name": "mike96angelo",
    "email": "mike96jong@gmail.com"
  },
  "_npmVersion": "3.10.10",
  "_phantomChildren": {},
  "_requested": {
    "raw": "bars@latest",
    "scope": null,
    "escapedName": "bars",
    "name": "bars",
    "rawSpec": "latest",
    "spec": "latest",
    "type": "tag"
  },
  "_requiredBy": [
    "#USER",
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/bars/-/bars-1.9.5.tgz",
  "_shasum": "c3dd29cc24100b0382609eac6619f5056a47f3d6",
  "_shrinkwrap": null,
  "_spec": "bars@latest",
  "_where": "/Users/mjong/Sites/Sidestorey/sidestory.com",
  "author": {
    "name": "Michaelangelo Jong"
  },
  "bugs": {
    "url": "https://github.com/Mike96Angelo/Bars/issues"
  },
  "dependencies": {
    "compileit": "^1.0.1",
    "generate-js": "^3.1.2",
    "jquery": "^3.1.1",
    "source-map": "^0.5.6",
    "virtual-dom": "^2.1.1"
  },
  "description": "Bars is a lightweight high performance HTML aware templating engine.",
  "devDependencies": {
    "browserify": "^13.1.1",
    "colors": "^1.1.2",
    "gulp": "^3.9.1",
    "gulp-minify": "0.0.14",
    "stringify": "^5.1.0",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "^1.1.0"
  },
  "directories": {},
  "dist": {
    "shasum": "c3dd29cc24100b0382609eac6619f5056a47f3d6",
    "tarball": "https://registry.npmjs.org/bars/-/bars-1.9.5.tgz"
  },
  "gitHead": "25ea9238b7d068da820a484c4b66dc7a6fa7ca03",
  "homepage": "https://github.com/Mike96Angelo/Bars#readme",
  "keywords": [
    "bars",
    "render",
    "renderer",
    "rendering",
    "template",
    "templating",
    "html"
  ],
  "license": "MIT",
  "main": "index.js",
  "maintainers": [
    {
      "name": "dallasread",
      "email": "dallas@excitecreative.ca"
    },
    {
      "name": "mike96angelo",
      "email": "mike96jong@gmail.com"
    }
  ],
  "name": "bars",
  "optionalDependencies": {},
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/Mike96Angelo/Bars.git"
  },
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "version": "1.9.5"
}
