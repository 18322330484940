initApp = require('.');

initApp({
    env: 'prod',
    logLevel: 'info',
    api: 'prod_9',
    api_url: 'https://awjboq5s6c.execute-api.us-east-1.amazonaws.com',
    cdn: 'https://dtwr9uud83x48.cloudfront.net'
});

module.exports = app;
