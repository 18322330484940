var BrowserRouter = require('browser-app-router');
var api = require('./api');
var Sender = require('./Sender');
var Story = require('./Story');
var $ = require('jquery');

function routes(app) {

    var router = app.router = new BrowserRouter({
        // mode: 'hash'
    });

    router.addRoute({
        title: 'Create Story',
        path: '/compose',
        handler: function (req) {
            if (!app.state.user) {
                // handle error
                return router.go('/login?redirect=' + encodeURIComponent(req.fullPath));
            }

            if (!app.composePage.newStory) {
                app.composePage.newStory = new Story();
            }

            app.composePage.loadData(app.state.user, app.composePage.newStory);

            app.state.load('compose');
        }
    });

    router.addRoute({
        title: 'Create Account',
        path: '/signup',
        handler: function (req) {
            if (app.state.user) {
                // handle error
                return router.go('/login');
            }

            // app.signupPage;

            app.state.load('signup');
        }
    });

    router.addRoute({
        title: 'Account',
        path: '/account',
        handler: function (req) {
            if (!app.state.user) {
                // handle error
                return router.go('/login?redirect=' + encodeURIComponent(req.fullPath));
            }

            app.accountPage.loadData(app.state.user);

            app.state.load('account');
        }
    });

    router.addRoute({
        title: 'Sidestory',
        path: '/login',
        handler: function (req) {
            if (app.state.user) {
                return router.go(router.req.search.redirect || '/' + app.state.user.username);
            }

            // app.loginPage;

            app.state.load('login');
        }
    });

    // for now...
    router.addRoute({
        title: 'Sidestory',
        path: '/login.html',
        handler: function (req) {
            if (app.state.user) {
                return router.go(router.req.search.redirect || '/' + app.state.user.username);
            }

            // app.loginPage;

            app.state.load('login');
        }
    });

    router.addRoute({
        title: 'Sidestory - App',
        path: '/{username}',
        handler: function (req) {
            // handle other users
            api.getProfile({
                username: req.params.username
            }, function (err, res) {
                if (err) {
                    logger.log(err);
                    return router.go404();
                }

                app.profilePage.loadData(res.user || res.sender, res.stories);

                app.state.load('profile');
            });
        }
    });

    router.addRoute({
        title: 'Sidestory - App',
        path: '/{username}/{webcode}',
        handler: function (req) {
            api.getStory({
                username: req.params.username,
                webcode: req.params.webcode
            }, function (err, res) {
                logger.log('/{username}/{webcode}', err, res);
                if (err && !res.story) {
                    return router.go404();
                }

                res.story.updates = res.updates;

                if (
                    app.state.user &&
                    res.sender.id === app.state.user.id &&
                    req.search.edit
                ) {
                    app.composePage.loadData(
                        res.sender,
                        res.story
                    );

                    app.state.load('compose');
                } else {
                    app.storyPage.loadData(
                        res.sender,
                        res.story
                    );

                    app.state.load('story');
                }
            });
        }
    });

    router.set404({
        title: 'Sidestory - Not Found',
        handler: function (req) {
            // app._404Page
            app.state.load('404');
        }
    });

    app.view.on('click', '[x-route]', function (evt, target) {
        router.go(target.getAttribute('x-route'));
    });


    router.start(window.appPreRendered);
}

module.exports = routes;
