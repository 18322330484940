var $ = require('jquery');

function mediaEvents(app) {
    logger.log('mediaEvents');
    var loadTimeout;

    function load(player) {
        player.removeListener('load', load);
        clearTimeout(loadTimeout);
        loadTimeout = setTimeout(function () {
            app.render();
        }, 100);
    }

    app.on('render', function () {
        $('.media-player-tag')
            .each(function () {
                if (this.data) {
                    var player = this.data('player');

                    if (player) {
                        player.bind(
                            this,
                            app.userAgent.browser.name !== 'Mobile Safari'
                        );

                        var count;

                        while (player.listenerCount('load') !== count) {
                            count = player.listenerCount('load');
                            player.removeListener('load', load);
                        }

                        player.addListener('load', load);
                    }
                }
            });
    });

    app.view.on('click', '.play-btn, .media-overlay', function (evt, target) {
        var player = target.data('player');
        player.play();
        app.render();

        function timeupdate() {
            if (player.playingState === 'playing') {
                player.__timeupdateTimeout__ = setTimeout(
                    function () {
                        app.render();

                        timeupdate();
                    },
                    100
                );
            }
        }

        timeupdate();
    });

    app.view.on('click', '.volume-btn img', function (evt, target) {
        var player = target.data('player');
        player.mute();
        app.render();
    });

    var _volumeSlidingEvent;

    function mkVolumeSlidingEvent(target, player) {
        _volumeSlidingEvent = function volumeSlidingEvent(evt) {
            player.volume = 100 * (evt.pageX - $(target)
                .offset()
                .left) / target.offsetWidth;

            player.mute(false);
            app.render();
        };
    }

    var _seekSlidingEvent;

    function mkSeekSlidingEvent(target, player) {
        _seekSlidingEvent = function volumeSlidingEvent(evt) {
            var seek = 100 * (evt.pageX - $(target)
                .offset()
                .left) / target.offsetWidth;

            player.seek(seek);

            app.render();
        };
    }

    app.view.on('mousedown', '.volume-slider-container', function (evt, target) {
        var player = target.data('player');
        mkVolumeSlidingEvent(target, player);
        _volumeSlidingEvent(evt);
        return false;
    });

    app.view.on('mousedown', '.media-time-line', function (evt, target) {
        var player = target.data('player');
        mkSeekSlidingEvent(target, player);
        _seekSlidingEvent(evt);
        return false;
    });

    app.window.on('mousemove', function (evt) {
        if (_volumeSlidingEvent) _volumeSlidingEvent(evt);
        if (_seekSlidingEvent) _seekSlidingEvent(evt);
    });

    app.window.on('mouseup blur', function (evt) {
        _volumeSlidingEvent = null;
        _seekSlidingEvent = null;
    });
}

module.exports = mediaEvents;
