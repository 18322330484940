var telMasker = require('./phone-mask')
    .masker;
var codeMasker = require('./code-mask')
    .masker;
var libphonenumber = require('libphonenumber-js');
var api = require('./api');
window.XRegExp = require('xregexp');

var USERNAME = {
    valid: new XRegExp('^[A-Za-z][A-Za-z0-9]{2,14}$'),
    validFirstChar: new XRegExp('^[A-Za-z]'),
    removeInvalidChars: new XRegExp(
        '(?:^[^A-Za-z]+)|(?:[^A-Za-z0-9]+)',
        'g'
    ),
    validChars: new XRegExp('^[A-Za-z0-9]*$')
};

window.FULLNAME = {
    valid: new XRegExp(
        '^(?:\\pL+(?:\\pL|[\\-]\\pL+|[\']\\pL*|\\.))(?:\\s\\pL+(?:\\pL|[\\-]\\pL+|[\']\\pL*|\\.))+\\s?$'
    ),
    validChars: new XRegExp('^\\pL(\\pL|\\-|\'|\\s|\\.)*$'),
    removeInvalidChars: new XRegExp(
        '(?:^[^\\pL]+)|(?:[^\\pL|\'|\\s|\\-|\\.])|((?:\'|\\s|\\-|\\.)\\s?)(?:\\s*(?:\'|\\-|\\.))+',
        'g'
    ),
    validName: new XRegExp(
        '^(?:\\pL+(?:\\pL+|[\\-]\\pL+|[\']\\pL*|\\.))'
    )
};

function usernameReason(value) {
    if (!USERNAME.validChars.test(value)) {
        return 'Special characters are not allowed';
    }

    if (!USERNAME.validFirstChar.test(value)) {
        return 'Usernames must start with a letter';
    }

    if (value.length < 3) {
        return 'Usernames must be at least 3 characters long';
    }

    if (value.length > 15) {
        return 'Usernames can\'t have more than 15 characters';
    }

    return '';
}

function fullNameReason(value) {
    if (!FULLNAME.validChars.test(value)) {
        return 'Numbers and special characters are not allowed';
    }

    var values = value.split(/\s+/);

    for (var i = 0; i < values.length; i++) {
        if (values[i] && !FULLNAME.validName.test(values[i])) {
            return 'Names must be at least 2 characters long or an initial follow by a (.)';
        }
    }

    if (!FULLNAME.valid.test(value)) {
        return 'Enter two or more names';
    }

    return '';
}

// validation functions

function phone(validation) {
    var value = validation.value;

    value = '+' + telMasker.unmask(value || '')
        .text;
    var valid = libphonenumber.isValidNumber(value);

    validation.setValidation({
        valid: valid,
        value: value,
        message: valid ? '' : 'Enter a valid phone number, including the country code and area code.'
    });
}

exports.phone = phone;

function picture(validation) {
    validation.setValidation({
        valid: true
    });
}

exports.picture = picture;

function code(validation) {
    var value = validation.value;

    value = codeMasker.unmask(value || '')
        .text;

    var valid = value.length === 6;

    validation.setValidation({
        valid: valid,
        value: value,
        message: valid ? '' : 'Enter the 6 digit code we sent to your phone'
    });
}

exports.code = code;

var __usernameTimeout;

function username(validation) {
    var value = validation.value;

    value = (value || '')
        .trim()
        .toLowerCase();
    var valid = USERNAME.valid.test(value);

    var goodVal = value.replace(USERNAME.removeInvalidChars, '');

    validation.setValidation({
        valid: valid ? null : false,
        inputValue: goodVal,
        value: goodVal,
        message: valid ? 'Checking availability' : usernameReason(value)
    });

    if (!valid && goodVal !== value) {
        clearTimeout(__usernameTimeout);
        __usernameTimeout = setTimeout(function () {
            username(validation);
            validation.callback(null, true);
        }, 1000);
    }

    if (valid) {
        api.checkAvailableUsername({
            username: validation.inputValue
        }, function (err, res) {
            if (err) {
                logger.log('availableUsername', err);
            }

            isAvailable = err ? false : res.available;

            validation.callback({
                valid: !!(!err && isAvailable),
                message: err ?
                    'Something went wrong, try again later' : (
                        isAvailable ? '' :
                        'Username is not available.'
                    )
            });
        });
    }
}

exports.username = username;

var __fullNameTimeout;

function fullName(validation) {
    var value = validation.value;

    value = (value || '')
        .replace(/\s+/, ' ');
    var valid = FULLNAME.valid.test(value);

    var goodVal = value.replace(FULLNAME.removeInvalidChars, '$1');

    validation.setValidation({
        valid: valid,
        inputValue: goodVal,
        value: goodVal,
        message: valid ? '' : fullNameReason(value)
    });

    if (!valid && goodVal !== value) {
        clearTimeout(__fullNameTimeout);
        __fullNameTimeout = setTimeout(function () {
            fullName(validation);
            validation.callback(null, true);
        }, 1000);
    }
}

exports.fullName = fullName;
