function upload(url, file, contentType, cb, progress) {

    logger.log('uploading', url, file, contentType);

    var xhr = new XMLHttpRequest();
    xhr.onerror = function (e) {
        // handle failture
        cb(e, null);
    };

    xhr.upload.addEventListener('progress', function (e) {
        // handle notifications about upload progress: e.loaded / e.total
        if (progress) {
            progress(e, Math.floor(e.loaded / e.total) * 100);
        }
    }, false);

    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status >= 200 && xhr.status <= 299) {
                // upload completed
                cb(null, xhr.responseText);
            } else {
                // failed with error messge from server
                cb(xhr.responseText, null);
            }
        }
    };

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-type', contentType);
    xhr.send(file);
}

module.exports = upload;
