var Generator = require('generate-js');

var Modal = Generator.generate(function Modal(app) {
    var _ = this;

    _.show = false;
    _.name = '';
    _.modals = {};
    _.app = app;

    app.view.on('click', '.open-modal', function (evt, $el) {
        var name = $el.data('name');

        _.open(name);
    });

    app.view.on('click', '.close-modal', function (evt, $el) {
        _.close();
    });
});

Modal.definePrototype({
    create: function create(modal) {
        var _ = this;

        _.modals[modal.name] = modal;
    },
    open: function open(name, notClosable) {
        var _ = this;

        _.name = name;

        _.notClosable = !!notClosable;

        _.show = null;

        _.app.render();

        _.show = true;

        setTimeout(function () {
            _.app.render();
        }, 0);
    },
    close: function close() {
        var _ = this;

        _.show = null;

        _.app.render();

        _.show = false;

        setTimeout(function () {
            _.app.render();
        }, 200);
    }
});

module.exports = Modal;
