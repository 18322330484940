var Generator = require('generate-js');
var events = require('events');
var assert = require('./assert');

var PageState = Generator.generateFrom(
    events.EventEmitter,
    function PageState() {
        var _ = this;
        events.EventEmitter.call(_);
    }
);

PageState.definePrototype({
    bind: function bind() {},
    blockNavigation: function blockNavigation() {}
});

var PageStateManager = Generator.generateFrom(
    events.EventEmitter,
    function PageStateManager() {
        var _ = this;
        events.EventEmitter.call(_);

        _._pages = {};
    }
);

PageStateManager.definePrototype({
    blockNavigation: function blockNavigation() {
        var _ = this;

        if (_.pageState) {
            return _.pageState.blockNavigation();
        }
    },
    bind: function bind(app) {
        var _ = this;

        for (var name in _._pages) {
            if (_._pages.hasOwnProperty(name)) {
                _._pages[name].bind(app);
            }
        }

        _.on('load', function (state) {
            app.render();
        });
    },
    registerPageState: function registerPageState(name, page) {
        var _ = this;
        assert(typeof name === 'string', 'name must be a string.');
        assert(PageState.isCreation(page), 'page must be an instance of PageState.');
        assert(!PageState.isCreation(_._pages[name]), 'cannot override: ' + name + '.');

        _._pages[name] = page;

        page.on('change', function () {
            if (_._pages[name] === page) {
                _.emit('load', _);
            }
        });
    },
    load: function load(name) {
        var _ = this;
        assert(typeof name === 'string', 'name must be a string.');
        assert(PageState.isCreation(_._pages[name]), 'page does not exist: ' + name +
            '.');

        _.page = name;
        _.pageState = _._pages[name];

        _.emit('load', _);
    }
});

PageStateManager.PageState = PageState;

module.exports = PageStateManager;
