var Generator = require('generate-js');
var LOG_LEVEL = {
    error: 1,
    warn: 2,
    debug: 3,
    info: 4,
    log: 5
};
var Logger = Generator.generate(function Logger(level) {
    var _ = this;

    _.logLevel = LOG_LEVEL[level] || 5;
});

Logger.definePrototype({
    setLogLevel: function setLogLevel(level) {
        var _ = this;

        _.logLevel = LOG_LEVEL[level] || 5;
    },
    error: function error() {
        var _ = this;

        if (_.logLevel >= LOG_LEVEL.error) {
            (console.error || console.log)
            .apply(console, Array.prototype.slice.call(arguments));
        }
    },
    warn: function warn() {
        var _ = this;

        if (_.logLevel >= LOG_LEVEL.warn) {
            (console.warn || console.log)
            .apply(console, Array.prototype.slice.call(arguments));
        }
    },
    debug: function debug() {
        var _ = this;

        if (_.logLevel >= LOG_LEVEL.debug) {
            (console.debug || console.log)
            .apply(console, Array.prototype.slice.call(arguments));
        }
    },
    info: function info() {
        var _ = this;

        if (_.logLevel >= LOG_LEVEL.info) {
            (console.info || console.log)
            .apply(console, Array.prototype.slice.call(arguments));
        }
    },

    log: function log() {
        var _ = this;

        if (_.logLevel >= LOG_LEVEL.log) {
            console.log.apply(console, Array.prototype.slice.call(arguments));
        }
    },
});

module.exports = Logger;
