var App = require('bars/compiled/app');
var API = require('./js/api');
var Logger = require('./js/Logger');
var PageStateManager = require('./js/state');
var Sender = require('./js/Sender');
var UAParser = require('ua-parser-js');
var $ = require('jquery');

window.logger = new Logger();

var uaParser = new UAParser();
var appState = new PageStateManager();

function init(env) {

    logger.log('Unsupported Browser', document.getElementById('unsupported'))

    if (document.getElementById('unsupported')) return;

    window.logger.setLogLevel(env.logLevel);

    API.init(env);

    appState.user = API.account ? new Sender(API.account.user) : null;

    appState.cdn = env.cdn;

    appState.userAgent = uaParser.getResult();

    var app = new App(
        // options
        {
            index: require('./index.bars'),
            partials: require('./partials'),
            transforms: require('./transforms')
        },

        // State
        appState
    );

    app.userAgent = appState.userAgent;
    app.API = API;
    app._env_ = env;

    window.app = app;

    app._404Page = require('./pages/404');
    app.accountPage = require('./pages/account');
    app.composePage = require('./pages/compose');
    app.loginPage = require('./pages/login');
    app.profilePage = require('./pages/profile');
    app.signupPage = require('./pages/signup');
    app.storyPage = require('./pages/story');

    appState.registerPageState('404', app._404Page);
    appState.registerPageState('account', app.accountPage);
    appState.registerPageState('compose', app.composePage);
    appState.registerPageState('login', app.loginPage);
    appState.registerPageState('profile', app.profilePage);
    appState.registerPageState('signup', app.signupPage);
    appState.registerPageState('story', app.storyPage);

    appState.bind(app);

    app.logout = function logout() {
        app.state.user = null;
        app.state.profile = null;
        app.state.story = null;
        app.state.newStory = null;
    };

    var joinForm = require('./js/join-form');

    appState.joinForm = joinForm;

    require('./js/form-bind')(app, joinForm);

    require('./js/routes')(app);
    require('./js/scroll-pager')(app);
    require('./js/media-events')(app);

    require('./js/modals')(app);
    require('./js/clipboard')(app);
    require('./js/nav')(app);
    require('./js/phone-mask')(app);
    require('./js/code-mask')(app);
    require('./js/input-blocker')(app);

    app.router.beforeRouteChange = function (evt) {
        return appState.blockNavigation();
    };

    // update timeFrom ever 30 seconds.
    app.renderInterval = setInterval(function () {
        app.render();
    }, 30 * 1000);


    app.appendTo(document.body); // do this last
}

module.exports = init;
