var PageState = require('../../js/state')
    .PageState;
var api = require('../../js/api');
var formBind = require('../../js/form-bind');
var loginForm = require('./login-form');

var LoginPage = PageState.generate(
    function LoginPage() {
        var _ = this;

        PageState.call(_);

        _.loginForm = loginForm.loginForm;
        _.loginCodeForm = loginForm.loginCodeForm;
    }
);

LoginPage.definePrototype({
    bind: function bind(app) {
        var _ = this;

        formBind(app, _.loginForm);
        formBind(app, _.loginCodeForm);

        app.view.on('click', '.go-logout', function (evt, $el) {
            // api call log out
            api.logout(function (err, res) {
                // do something with err.
                app.state.user = null;
                app.router.go(
                    '/login?redirect=' +
                    encodeURIComponent(
                        app.router.req.fullPath
                    )
                );
                window.location.reload();
            });
        });

        app.view.on('click', '.go-login', function (evt, target) {
            app.router.go(
                '/login?redirect=' +
                encodeURIComponent(
                    app.router.req.fullPath
                )
            );
        });
    }
});

module.exports = LoginPage;
