var Generator = require('generate-js');
var events = require('events');

var ImagePlayer = Generator.generateFrom(
    events.EventEmitter,
    function ImagePlayer(imageTag) {
        var _ = this;

        events.EventEmitter.call(_);

        _.imageTag = imageTag;
    }
);

ImagePlayer.definePrototype({
    _load_: function _load_() {
        var _ = this;

        loaded = (_.imageTag && _.imageTag.complete) ||
            (_.imageTag && _.imageTag.readyState === 4);

        if (!loaded) {
            setTimeout(function () {
                _._load_();
            }, 50);

            return;
        }

        _.emit('load', _);
    },
    bind: function bind(imageTag, removeControls) {
        var _ = this;

        if (imageTag !== _.imageTag) {
            _.imageTag = imageTag;

            _._load_();
        }
    },
    destroy: function destroy() {
        var _ = this;

        _.imageTag = null;
    }
});

module.exports = ImagePlayer;
