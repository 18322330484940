function request(url, options, cb) {
    method = options.method || 'GET';

    var xhr = new XMLHttpRequest();

    if (!xhr) {
        return false;
    }

    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            cb(xhr.status, xhr.responseText);
        }
    };

    xhr.open(method, url, true);

    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(options.body);
}

function IE_request(url, options, cb) {

    method = options.method || 'GET';

    var xdr = new XDomainRequest();

    if (!xdr) {
        return false;
    }

    xdr.onerror = function () {
        //Handle Error
        cb(404, xdr.responseText);
    };

    xdr.onload = function () {
        cb(200, xdr.responseText);
    };

    xdr.open(method, url, true);
    xdr.send(options.body);
}


module.exports = window.XDomainRequest ? IE_request : request;
