var StoryPage = require('../story/StoryPage');

var $ = require('jquery');
var TYPES = {
    image: 'photo',
    video: 'video',
    audio: 'audio'
};

var api = require('../../js/api');
var Sender = require('../../js/Sender');
var Story = require('../../js/Story');

function select(el, start, end) {
    if (el.createTextRange) {
        var part = el.createTextRange();
        part.moveStart("character", start);
        part.moveEnd("character", end);
        part.select();
    } else if (el.setSelectionRange) {
        el.setSelectionRange(start, end || start);
    }
    el.focus();
}

function fixTextAreaHeight(target) {
    target.style.overflow = 'scroll';
    target.style.height = '0';
    target.style.height = (Math.max(target.scrollHeight, 20)) + 'px';
    target.style.overflow = 'hidden';
}

var ComposePage = StoryPage.generate(
    function ComposePage() {
        var _ = this;

        StoryPage.call(_);
    }
);

ComposePage.definePrototype({
    blockNavigation: function blockNavigation() {
        var _ = this;

        if (_.story && _.story.hashChanges) {
            return _.story.hashChanges() ?
                'You have unsaved changes, are you sure you want leave this page?' :
                void(0);
        }
    },
    bind: function bind(app) {
        var _ = this;

        _.app = app;

        app.view.on('click', '.save-story', function (evt, target) {
            var story = target.data('story');

            if (story.hashChanges()) {
                app.modal.open('upload-story', true);

                story.save(function (err, res) {
                    logger.log(err, res);

                    app.modal.close();
                    _.newStory = null;
                }, function progress(item) {

                    app.router.go(app.router.req.path);
                });
            } else {
                app.router.go(app.router.req.path);
            }
        });

        app.view.on('click', '.share-story', function (evt, target) {
            var story = target.data('story');

            if (!story.messageId && story.hashChanges()) {
                app.modal.open('upload-story', true);
                story.save(function (err, res) {
                    logger.log(err, res);
                    app.modal.open('share-story');
                }, function progress(item) {
                    app.render();
                });
            } else if (story.messageId) {
                app.modal.open('share-story');
            }
        });

        app.view.on('click', '.make-public', function (evt, target) {
            var story = target.data('story');

            api.sendMessage({
                messageId: story.messageId,
                to: [{
                    type: 'outbound',
                    value: 'public'
                }]
            }, function (err, res) {
                logger.log(err, res);
            });

            app.modal.close();
        });

        app.view.on(
            'input',
            'textarea.add-text',
            function (evt, target) {
                var item = target.data('item');
                var story = target.data('story');

                var lines = target.value.split('\n');
                var newItem;

                if (item.type === 'text') {
                    item.value = lines[0];

                    for (var i = 1; i < lines.length; i++) {
                        if (lines[i]) {
                            newItem = item.insertAfter({
                                type: 'text',
                                style: 'p',
                                value: lines[i]
                            });
                        }
                    }
                }

                app.render();

                if (newItem) {
                    story.focusItem(newItem);

                    var $n = $('#story-row-' + story.indexOf(newItem))
                        .find('textarea.add-text');

                    $n = $n[0];
                    if ($n) {
                        select($n, newItem.value.length);
                    }
                }
            }
        );

        app.view.on(
            'focus',
            'textarea.add-text',
            function (evt, target) {
                var item = target.data('item');
                var story = target.data('story');

                if (item.type === 'text') {
                    story.focusItem(item);
                    app.render();
                }
            }
        );

        app.view.on(
            'blur',
            'textarea.add-text',
            function (evt, target) {
                var item = target.data('item');
                var story = target.data('story');

                if (item.type === 'text') {
                    story.focusItem(null);
                    setTimeout(function () {
                        if (story.hasItem(item)) {
                            app.render();
                        }
                    }, 200);
                }
            }
        );

        app.view.on('keydown', 'textarea.add-text', function (evt, target) {
            var story = target.data('story');
            var item = target.data('item');
            var index = story.indexOf(item);
            var id;

            var start = target.selectionStart;
            var end = target.selectionEnd;
            var value = item.value;

            if (evt.keyCode !== 13) {
                _.typing = true;
                clearTimeout(_.typingTimeout);
                _.typingTimeout = setTimeout(function () {
                    _.typing = false;
                    app.render();
                }, 625);
                app.render();
            }

            if (evt.keyCode === 13) { // enter
                evt.preventDefault();

                item.value = value.slice(0, start);

                var text = {
                    type: 'text',
                    style: 'p',
                    value: value.slice(end)
                };

                var newItem;

                if (text.value || item.next()) {
                    newItem = item.insertAfter(text);
                } else {
                    newItem = story.addItem(text);
                }

                story.focusItem(newItem);

                app.render();

                var $n = $('#story-row-' + story.indexOf(newItem))
                    .find('textarea.add-text');

                $n = $n[0];
                if ($n) {
                    select($n, 0);
                }
            } else if (
                evt.keyCode === 8 &&
                start === 0 &&
                end === 0
            ) { // backspace
                logger.log('delete text', item);
                evt.preventDefault();

                var prev = item.prev();

                if (prev && prev.type === 'text') {
                    prevLength = prev.value.length;
                    prev.value += item.value;

                    item.remove();

                    story.focusItem(prev);

                    app.render();

                    var $n = $('#story-row-' + story.indexOf(prev))
                        .find('textarea.add-text');

                    $n = $n[0];
                    if ($n) {
                        select($n, prevLength);
                    }
                } else if (!item.value) {
                    item.remove();
                    app.render();
                }
            }
        });

        app.view.on('keydown keyup input', 'textarea.add-text', function (evt, target) {
            fixTextAreaHeight(target);
        });

        app.view.on('click', '.row.text', function (evt, target) {
            $(target)
                .find('textarea.add-text')
                .focus();
        });

        app.view.on('click',
            '.media .add-tool, .media .add-text-btn, .row.last, .placeholder',
            function (evt, target) {
                if (evt.target !== target) {
                    return;
                }

                var story = target.data('story');
                var item = target.data('item');

                var text = {
                    type: 'text',
                    style: 'p',
                    value: ''
                };

                var newItem;

                if (item) {
                    newItem = item.insertBefore(text);
                } else {
                    newItem = story.addItem(text);
                }

                story.focusItem(newItem);

                app.render();

                $('#story-row-' + story.indexOf(newItem))
                    .find('textarea.add-text')
                    .focus();
            });

        app.view.on('change', '.add-media-btn', function (evt, target) {
            var story = target.data('story');
            var item = target.data('item');

            var file = target.files[0];
            if (file) {
                var media = {
                    type: TYPES[file.type.split('/')[0]],
                    value: URL.createObjectURL(file),
                    file: {
                        file: file,
                        name: file.name,
                        type: file.type
                    }
                };

                var newItem;

                if (item) {
                    newItem = item.insertBefore(media);
                } else {
                    newItem = story.addItem(media);
                }

                var _scrolledIntoVeiw = false;

                app.once('after-render', function () {
                    function load() {
                        if (!newItem.player) {
                            return;
                        }
                        logger.log(newItem, newItem.player);

                        var media = newItem.player.videoTag ||
                            newItem.player.imageTag;
                        var loaded = (media && media.complete) ||
                            (media && media.readyState === 4);

                        if (!_scrolledIntoVeiw && media) {
                            _scrolledIntoVeiw = true;
                            logger.log('sroll', !!media.scrollIntoView);
                            // if (media.scrollIntoView)
                            //     media.scrollIntoView();
                        }

                        logger.log('make preview? load', media, loaded);

                        if (!loaded) {
                            return setTimeout(load, 100);
                        }

                        logger.log('make preview?');

                        newItem.height = (
                                media ?
                                media.naturalHeight ||
                                media.videoHeight : 0
                            ) ||
                            $media.height();

                        newItem.width = (
                                media ?
                                media.naturalWidth ||
                                media.videoWidth : 0
                            ) ||
                            $media.width();

                        if (newItem.type === 'video') {
                            // make preview image
                            var canvas = document.createElement(
                                'canvas');

                            canvas.width = newItem.width;
                            canvas.height = newItem.height;

                            var ctx = canvas.getContext('2d');
                            ctx.drawImage(media, 0, 0, newItem.width,
                                newItem.height);

                            canvas.toBlob(function (img) {
                                newItem.previewFile = {
                                    file: img,
                                    name: 'preview.png',
                                    type: 'image/png'
                                };

                                newItem.preview = URL.createObjectURL(
                                    img
                                );
                            }, 'image/png');
                        }

                        logger.log([media], newItem);
                        app.render();
                    }

                    load();
                });


                app.render();
            }
        });

        app.view.on('click', '.remove-media-btn', function (evt, target) {
            var item = target.data('item');

            item.remove();

            app.render();

            return false;
        });

        app.view.on('click', '.style-btn', function (evt, target) {
            var item = target.data('item');

            item.style = target.data('style');

            app.render();
        });

        function fixAddTextHeight() {
            app.view.$element.find('textarea.add-text')
                .each(function () {
                    fixTextAreaHeight(this);
                });
        }

        app.on('after-append', fixAddTextHeight);
        app.on('after-render', fixAddTextHeight);
    }
});

module.exports = ComposePage;
