var urls = /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gi;
var emails = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gi;

function smartLinkParse(str) {
    str = String(str);

    var results = [];
    var match = null;
    var index = 0;
    var prev;

    while ((match = urls.exec(str)) !== null) {
        prev = str.slice(index, match.index);

        if (prev) {
            results.push({
                type: 'text',
                value: prev
            });
        }

        results.push({
            type: 'link',
            value: match[0]
        });

        index = match.index + match[0].length;
    }

    prev = str.slice(index);

    if (prev) {
        results.push({
            type: 'text',
            value: prev
        });
    }

    return results;
}


module.exports = {
    smartLinkParse: smartLinkParse
};
