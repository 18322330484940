var CryptoJS = require("crypto-js");
var SecureStorage = require("secure-web-storage");

function getSecureStorage(storage, secretKey) {

    function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, secretKey);

        data = data.toString();

        return data;
    }

    function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, secretKey);

        data = data.toString(CryptoJS.enc.Utf8);

        return data;
    }

    function hash(key) {
        key = CryptoJS.SHA256(key + secretKey);

        return key.toString();
    }

    return new SecureStorage(storage, {
        hash: hash,
        encrypt: encrypt,
        decrypt: decrypt,
    });
}

module.exports = getSecureStorage;
