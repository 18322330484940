var Croppie = require('croppie');

var PageState = require('../../js/state')
    .PageState;

var formBind = require('../../js/form-bind');
var Sender = require('../../js/Sender');
var api = require('../../js/api');


var accountForm = require('./account-form');

var AccountPage = PageState.generate(
    function AccountPage() {
        var _ = this;

        PageState.call(_);

        _.accountForm = accountForm;
    }
);

AccountPage.definePrototype({
    loadData: function loadData(data) {
        var _ = this;

        _.accountForm.fill(data);
    },
    loadImage: function loadImage(file) {
        var _ = this;

        if (_.croppie) {
            _.croppie.bind({
                url: URL.createObjectURL(file)
            });
        }
    },
    getImage: function getImage(cb) {
        var _ = this;

        if (_.croppie) {
            _.croppie.result({
                    type: 'blob',
                    size: {
                        height: 320,
                        width: 320,
                    },
                    format: 'jpeg',
                    quality: 1,
                    circle: false
                })
                .then(function (img) {
                    cb(null, {
                        file: img,
                        name: 'profile.jpg',
                        type: 'image/jpeg'
                    });
                }, function (err) {
                    cb(err, null);
                });
        } else {
            cb('No image', null);
        }
    },
    bind: function bind(app) {
        var _ = this;

        app.view.on('change', 'input#profile-croppie-input', function (evt, target) {
            _.file = target.files[0];
            _.accountForm.changingPicture = 1;
            app.signupPage.changingPicture = 1;
            _.loadImage(_.file);

            app.render();
        });

        app.view.on('click', '#profile-croppie-upload', function (evt, target) {
            if (_.accountForm.changingPicture) {
                _.getImage(function (err, file) {
                    if (err) {
                        return logger.log('Croppie Error: ', err);
                    }

                    delete _.accountForm.changingPicture;
                    delete app.signupPage.changingPicture;
                    _.file = null;

                    api.updateProfilePicture(file, function (err, res) {
                        logger.log(err, res);
                        app.state.user = api.account ? new Sender(
                            api.account.user) : null;

                        if (app.state.page !== 'account') {
                            app.router.go(app.router.req.search
                                .redirect || app.state.user
                                .alias
                            );
                        }
                        app.render();
                    });
                });
            } else if (app.state.page !== 'account') {
                app.router.go(app.router.req.search
                    .redirect || app.state.user
                    .alias
                );
            }
        });

        app.view.on('click', '#profile-croppie-cancel', function (evt, target) {
            delete _.accountForm.changingPicture;
            _.file = null;
            app.render();
        });

        app.on('after-render', function () {
            if (
                AccountPage.isCreation(app.state.pageState) ||
                (app.state.page === 'signup' && app.state.pageState.choosePicture)
            ) {
                var profileCroppie = document.getElementById('profile-croppie');
                if (profileCroppie && !_.croppie) {
                    _.croppie = new Croppie(
                        profileCroppie, {
                            viewport: {
                                width: 75,
                                height: 75,
                                type: 'circle'
                            },
                            boundary: {
                                width: 100,
                                height: 100
                            },

                        }
                    );
                }
            }
        });

        app.on('before-render', function () {
            if (!AccountPage.isCreation(app.state.pageState) &&
                !(app.state.page === 'signup' && app.state.pageState.choosePicture)
            ) {
                if (_.croppie) {
                    _.croppie.destroy();
                    _.croppie = null;
                }
            }

            app.state.user = api.account ? new Sender(api.account.user) : null;
        });

        formBind(app, _.accountForm);
    }
});

module.exports = AccountPage;
