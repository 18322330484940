var Form = require('check-form');
var api = require('../../js/api');
var Sender = require('../../js/Sender');
var validation = require('../../js/validation');

var signupForm = new Form({
    name: 'create-account',
    fields: {
        phone: validation.phone,
        username: validation.username,
        fullName: validation.fullName
    },
    action: function action(data) {
        var _ = this;
        var app = _.app;

        api.otpSignup(data, function (err, res) {
            logger.log(err, res);
            if (err || !res.updated) {
                callback(err, res);
            } else {
                _.reset();

                if (res.updated) {
                    app.state.user = new Sender(api.account.user);

                    app.signupPage.choosePicture = true;
                    app.render();
                    // app.router.go(app.router.req.search.redirect || '/compose');
                } else {
                    // something went wrong
                }

                callback(err, res);
            }
        });
    }
});

module.exports = signupForm;
