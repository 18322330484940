var Masker = require('maskerjs');

var telMasker = new Masker(
    [
        '+_-___-___-____',
        '+__-___-___-____',
        '+___-___-___-____',
        '+___-___-____-____',
        '+___-____-____-____'
    ],
    /^[0-9]$/ // allowed chars
);

function phoneMask(app) {
    app.view.on('input', 'input[type="tel"]', telMasker.inputListener);
    app.view.on('keydown', 'input[type="tel"]', telMasker.keydownListener);
}

phoneMask.masker = telMasker;

module.exports = phoneMask;
