var Generator = require('generate-js');
var events = require('events');

function ntos(n, l) {
    var r = '' + n;
    while (r.length < l) {
        r = '0' + r;
    }
    return r;
}

function timeToStr(n, op) {
    op = op || {};
    if (op.mil) {
        n *= 1000;
    }
    n = Math.floor(n);
    var mil = 0,
        sec = 0,
        min = 0,
        hour = 0;

    var r = '';

    if (op.mil) {
        mil = n % 1000;
        n = Math.floor(n / 1000);
        sec = n % 60;
        n = Math.floor(n / 60);
        min = n % 60;
        n = Math.floor(n / 60);
        hour = n;
    } else {
        sec = n % 60;
        n = Math.floor(n / 60);
        min = n % 60;
        n = Math.floor(n / 60);
        hour = n;
    }

    function ntos(n, l) {
        var r = '' + n;
        while (r.length < l) {
            r = '0' + r;
        }
        return r;
    }
    var bigger = op.hour || hour;

    if (bigger) r += hour;

    if (bigger) r += ':' + ntos(min, 2);
    else if (op.min || min) r += min;

    bigger = bigger || op.min || min;

    if (bigger) r += ':' + ntos(sec, 2);
    else if (op.sec || sec) r += sec;

    bigger = bigger || op.sec || sec;

    if (bigger && op.mil) r += '.' + ntos(mil, 3);
    else if (op.mil) r += mil;

    return r || '0';
}
T = [1, 60, 60 * 60];

function strToTime(str) {
    var t = str.split(':');

    var r = 0;

    for (var i = 0; i < t.length; i++) {
        r += Number(t[i]) * T[t.length - 1 - i];
    }
    return r;
}

var VideoPlayer = Generator.generateFrom(
    events.EventEmitter,
    function VideoPlayer(videoTag) {
        var _ = this;

        events.EventEmitter.call(_);

        _.videoTag = videoTag;

        _.__players__.push(_);
    }
);

VideoPlayer.definePrototype({
    __players__: [],
    timeText: {
        get: function get() {
            var _ = this;

            var timeOps = {
                // hour: _.totalTime >= 60 * 60, // 0:00:00.000
                min: true, // 0:00.000
                // sec: true, // 0.000
                // mil: true // show milliseconds
            };

            return timeToStr(_.currentTime, timeOps) + ' / ' + timeToStr(_.totalTime,
                timeOps);
        }
    },
    buffered: {
        get: function get() {
            var _ = this;

            if (!_.videoTag) return 0;

            var range = 0;
            var bf = _.videoTag.buffered;
            var time = _.videoTag.currentTime;
            var loadEndPercentage = 0;

            for (; range < bf.length; range++) {
                if (bf.start(range) <= time && time <= bf.end(range)) {
                    loadEndPercentage = bf.end(range) / _.videoTag.duration;
                    break;
                }
            }

            return Math.min(loadEndPercentage * 100, 100);
        }
    },
    played: {
        get: function get() {
            var _ = this;

            return _.currentTime / _.totalTime * 100;
        }
    },
    currentTime: {
        get: function get() {
            var _ = this;

            return _.videoTag ?
                (_.videoTag.currentTime || 0) :
                0;
        }
    },
    totalTime: {
        get: function get() {
            var _ = this;

            return _.videoTag ?
                (_.videoTag.duration || 0) :
                0;
        }
    },
    playingState: {
        get: function get() {
            var _ = this;

            var paused = _.videoTag ?
                _.videoTag.paused :
                true;

            return paused ?
                'paused' :
                'playing';
        }
    },
    volume: {
        get: function get() {
            var _ = this;

            return (
                _.videoTag && !_.videoTag.muted ?
                _.videoTag.volume :
                0
            ) * 100;
        },
        set: function set(val) {
            var _ = this;

            if (_.videoTag) {
                _.videoTag.volume = Math.max(0, Math.min(val / 100, 1));
            }
        }
    },
    volumeState: {
        get: function get() {
            var _ = this;

            var muted = _.videoTag ?
                _.videoTag.muted :
                false;

            var index;

            if (_.volume === 0) {
                index = 0;
            } else if (_.volume < 33) {
                index = 1;
            } else if (_.volume < 66) {
                index = 2;
            } else {
                index = 3;
            }

            return muted ? 'mute' : [
                'volume-0',
                'volume-1',
                'volume-2',
                'volume-3'
            ][index];
        }
    },
    _play_: function _play_() {
        var _ = this;

        for (var i = 0; i < _.__players__.length; i++) {
            if (_.__players__[i] !== _) {
                _.__players__[i].play(false);
            }
        }
    },
    play: function play(state) {
        var _ = this;

        if (!_.videoTag) return;

        if (state === false || state === true) {
            if (state) {
                _._play_();
                _.videoTag.play();
            } else {
                _.videoTag.pause();
            }
        } else {
            if (_.videoTag.paused) {
                _._play_();
                _.videoTag.play();
            } else {
                _.videoTag.pause();
            }
        }
    },
    seek: function seek(n) {
        var _ = this;

        if (!_.videoTag) return;

        _.videoTag.currentTime = n / 100 * _.videoTag.duration;
    },
    mute: function mute(state) {
        var _ = this;

        if (!_.videoTag) return;

        if (state === false || state === true) {
            _.videoTag.muted = state;
        } else {
            _.videoTag.muted = !_.videoTag.muted;
        }
    },
    _load_: function _load_() {
        var _ = this;

        loaded = (_.videoTag && _.videoTag.complete) ||
            (_.videoTag && _.videoTag.readyState === 4);

        if (!loaded) {
            setTimeout(function () {
                _._load_();
            }, 50);

            return;
        }

        _.emit('load', _);
    },
    bind: function bind(videoTag, removeControls) {
        var _ = this;

        if (videoTag !== _.videoTag) {
            _.videoTag = videoTag;

            if (removeControls) {
                _._controls = _.videoTag.controls;
                _.videoTag.controls = false;
            }

            _._load_();
        }
    },
    destroy: function destroy() {
        var _ = this;

        var index = _.__players__.indexOf(_);

        if (index !== -1) {
            if (_.videoTag && _._controls !== void(0)) {
                _.videoTag.controls = _._controls;
            }
            _.__players__.splice(index, 1);
        }
    }
});

module.exports = VideoPlayer;
