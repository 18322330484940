var Masker = require('maskerjs');

var codeMasker = new Masker(
    [
        '_ _ _ _ _ _',
    ],
    /^[0-9]$/ // allowed chars
);

function codeMask(app) {
    app.view.on('input', 'input[name="code"]', codeMasker.inputListener);
    app.view.on('keydown', 'input[name="code"]', codeMasker.keydownListener);
}

codeMask.masker = codeMasker;

module.exports = codeMask;
