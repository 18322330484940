var moment = require('moment');

module.exports = {
    timeFrom: function timeFrom(date) {
        return moment(date, 'X')
            .fromNow();
    },
    todaysDate: function todaysDate() {
        return moment()
            .format('LL');
    },
    date: function date(date) {
        return moment(date, 'X')
            .format('LL');
    },
    filterMedia: function filterMedia(arr) {
        if (arr instanceof Array) {
            arr = arr.filter(function (item) {
                return item && item.file;
            });
        }

        return arr;
    },
    previewImage: function previewImage(username, webCode, useOld) {
        // var url = username + '/' + webCode + '.jpg';
        var url = webCode + '.jpg';

        return 'https://d33ebmjpjljz1r.cloudfront.net/' + url;
    },

    mediaCDNLow: function mediaCDNLow(url) {
        //fix broken urls
        return url ? url.replace(
            'd33ebmjpjljz1r.cloudfront.net',
            'd33ebmjpjljz1r.cloudfront.net/low'
        ) : void(0);
    },

    isBool: function isBool(a) {
        return typeof a === 'boolean';
    },

    generateTitle: function generateTitle(story) {
        var _ = this;

        if (story && story.content) {
            for (var i = 0; i < story.content.length; i++) {
                if (story.content[i].type === 'text') {
                    return story.content[i].value.slice(0, 32);
                }
            }
        }

        return 'Untitled';
    },
    stringify: function stringify(data) {
        var _ = this;

        return JSON.stringify(data);
    },

    smartLinkParse: require('./components/smart-formatter')
        .transforms.smartLinkParse
};
