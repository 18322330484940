var Form = require('check-form');
var request = require('./request');
var validation = require('./validation');

var joinForm = new Form({
    name: 'join-form',
    fields: {
        phone: validation.phone
    },
    action: function (data, callback) {
        request('https://sidestory.com/sendDownloadLink/', {
            method: 'POST',
            body: JSON.stringify({
                device: platform.description,
                phone: data.phone
            })
        }, function (status, res) {
            callback(status === 200 ? null : 'Something went worng', res);
        });
    }
});

module.exports = joinForm;
